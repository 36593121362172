import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer, Layout, Menu} from "antd";

import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav, updateWindowWidth} from "../../appRedux/actions/Setting";
import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import {UploadOutlined, UserOutlined, VideoCameraOutlined} from "@ant-design/icons";
import Helpers from "../../util/Helpers";
import {MAIN_MENU} from "../../constants/ServerUrl";

const {Sider} = Layout;

const Sidebar = (props) => {

    const dispatch = useDispatch();
    const {sidebarCollapsed} = props;
    const helper = new Helpers();
    const {themeType, navStyle} = useSelector(({settings}) => settings);
    const {navCollapsed} = useSelector(({common}) => common);


    const onToggleCollapsedNav = () => {
        //  dispatch(toggleCollapsedSideNav(!navCollapsed));
    };

    useEffect(() => {
        // setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR)
    }, [navStyle])

    useEffect(() => {
        window.addEventListener('resize', () => {
            //  dispatch(updateWindowWidth(window.innerWidth));
        })
    }, [dispatch]);


    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={sidebarCollapsed}
            width={"222"}

            className="overflow-auto h-screen bg-blue"

        >
            <div className="flex items-center justify-center py-3 bg-blue-50 bg-opacity-90 cursor-pointer"
                 onClick={() => {
                     window.location.href = MAIN_MENU;
                 }}>

                <img className="w-36" alt="Phillips Outsourcing Logo" src="/images/logo.png"/>
            </div>
            <SidebarContent/>


        </Sider>
    )
};
export default Sidebar;
