import {
    BUSINESS_IDENTITY,
    BUSINESS_INFORMATION,
    CONTACT_PERSONS, DEFAULT_COMPANY, FETCH_ERROR,
    FETCH_START, FETCH_SUCCESS,
    RESET_COMPANY, SIGNOUT_USER_SUCCESS, USER_DATA
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import {DEFAULT_COMPANY_URL} from "../../constants/ServerUrl";

export const setBusinessInformation = (data) => {
    return {
        type: BUSINESS_INFORMATION,
        payload: data
    };
}

export const setContactPersons = (data) => {
    return {
        type: CONTACT_PERSONS,
        payload: data
    };
}

export const setBusinessIdentity = (data) => {
    return {
        type: BUSINESS_IDENTITY,
        payload: data
    };
}

export const resetCompanyInformation = () => {
    return {
        type: RESET_COMPANY,
    };
}


export const getDefaultCompany = (location = "/") => {
    // console.log();
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = " application/json";
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;

    return (dispatch) => {
        //dispatch({type: FETCH_START});
        axios.get(DEFAULT_COMPANY_URL).then(({data}) => {
            if (data.success) {

                //dispatch({type: FETCH_SUCCESS});
                dispatch({type: DEFAULT_COMPANY, payload: data.data});

            }
        }).catch(function (error) {
            //  dispatch({type: FETCH_ERROR, payload: error.message});

            console.log("Error****:", error.message);
        });
    }
};