import {
    BUSINESS_IDENTITY,
    BUSINESS_INFORMATION,
    CONTACT_PERSONS, DEFAULT_COMPANY, RESET_COMPANY,
    SIGNOUT_USER_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
    businessInformation: null,
    contactPersons: null,
    businessIdentity: null,
    defaultCompany: null,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BUSINESS_INFORMATION: {
            return {
                ...state,
                businessInformation: action.payload,
            }
        }

        case CONTACT_PERSONS: {
            return {
                ...state,
                contactPersons: action.payload,
            }
        }
        case BUSINESS_IDENTITY: {
            return {
                ...state,
                businessIdentity: action.payload,
            }
        }
        case RESET_COMPANY: {
            return {
                ...state,
                businessInformation: null,
                businessIdentity: null,
                contactPersons: null,

            }
        }
        case DEFAULT_COMPANY: {
            return {
                ...state,
                defaultCompany: action.payload,
            }
        }

        default:
            return state;
    }


}
