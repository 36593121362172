import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, SIGNOUT_USER_SUCCESS,
    SWITCH_LANGUAGE, SYSTEM_SETTINGS,
    TOGGLE_COLLAPSED_NAV, USER_DATA,
    WINDOW_WIDTH
} from "../../constants/ActionTypes";
import {LAYOUT_TYPE, NAV_STYLE, THEME_COLOR_SELECTION, THEME_TYPE} from "../../constants/ThemeSetting";
import axios from "../../util/Api";


export function toggleCollapsedSideNav(navCollapsed) {
    return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}

export function updateWindowWidth(width) {
    return (dispatch) => {
        dispatch({type: WINDOW_WIDTH, width});
    }

}

export function setThemeType(themeType) {
    return (dispatch) => {
        dispatch({type: THEME_TYPE, themeType});
    }
}

export function setThemeColorSelection(colorSelection) {
    return {type: THEME_COLOR_SELECTION, colorSelection};
}

export function onNavStyleChange(navStyle) {
    return (dispatch) => {
        dispatch({type: NAV_STYLE, navStyle});
    }
}

export function onLayoutTypeChange(layoutType) {
    return (dispatch) => {
        dispatch({type: LAYOUT_TYPE, layoutType});
    }
}

export function switchLanguage(locale) {
    return (dispatch) => {
        dispatch({
            type: SWITCH_LANGUAGE,
            payload: locale
        });
    }
}


export const getSystemSettings = (location = "/") => {
    // console.log();
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = " application/json";
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;

    return (dispatch) => {

        axios.get('user').then(({data}) => {
            console.log("settings: ", data);
            if (data.success) {

                dispatch({type: SYSTEM_SETTINGS, payload: data.settings});

            }


        }).catch(function (error) {

        });
    }
};
