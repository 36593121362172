import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {HomeFilled, SettingOutlined, UploadOutlined, UserOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {FaCity, FaUsers, FaCog, FaBars, FaBook} from "react-icons/fa";
import Helpers from "../../util/Helpers";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    const {authorizations} = useSelector(({auth}) => auth);

    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>


            <div className="flex items-center justify-center py-2 px-2">
                <UserProfile/>
                {/*<AppsNavigation/>*/}
            </div>


            <Menu
                className="bg-blue text-white text-center text-base font-medium my-5"
                mode="inline"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>

                <MenuItemGroup key="dashboard" className="mb-5">

                    <Menu.Item key="home">
                        <Link to="/home" className="flex">
                            <div className="text-2xl">
                                <HomeFilled/>
                            </div>
                            <span className="mx-4 py-1">Home</span>
                        </Link>
                    </Menu.Item>

                </MenuItemGroup>

                <MenuItemGroup key="accounting" className="gx-menu-group"
                               title={<div className="px-3 text-left text-white">ACCOUNTING</div>}>

                    {(Helpers.authorizeMe(authorizations[2], 'view', 11)
                        ||
                        Helpers.authorizeMe(authorizations[2], 'view', 12)
                        ||
                        Helpers.authorizeMe(authorizations[2], 'view', 13)
                    )
                    && (
                        <SubMenu key="company"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaCity/>
                                     </div>
                                     <div className="px-8 -my-3">Company</div>
                                 </div>}>
                            {Helpers.authorizeMe(authorizations[2], 'view', 11) &&

                            (

                                <Menu.Item key="company/companies">
                                    <Link to="/company/companies">

                                        <span className="pl-7 text-gray-200 text-sm">Business</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[2], 'view', 12) &&

                            (
                                <Menu.Item key="company/accounts">
                                    <Link to="/company/accounts">
                                        <span className="pl-7 text-gray-200 text-sm">Accounts</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[2], 'view', 12) &&

                            (
                                <Menu.Item key="company/account-summaries">
                                    <Link to="/company/account-summaries">
                                        <span className="pl-7 text-gray-200 text-sm">Account Summary</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[2], 'view', 13) &&

                            (
                                <Menu.Item key="company/items">
                                    <Link to="/company/items">
                                        <span className="pl-7 text-gray-200 text-sm">Items</span>
                                    </Link>
                                </Menu.Item>)}

                        </SubMenu>)}
                    <SubMenu key="payroll"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">
                                     <FaBook/>
                                 </div>
                                 <div className="px-8 -my-3">Payroll</div>
                             </div>}>
                        {Helpers.authorizeMe(authorizations[2], 'view', 11) &&
                        (
                            <Menu.Item key="payroll/payroll-center">
                                <Link to="/payroll/payroll-center">

                                    <span className="pl-7 text-gray-200 text-sm">Payroll Center</span>
                                </Link>
                            </Menu.Item>)}
                        {Helpers.authorizeMe(authorizations[2], 'view', 11) &&
                        (
                            <Menu.Item key="payroll/payroll-approvals">
                                <Link to="/payroll/payroll-approvals">

                                    <span className="pl-7 text-gray-200 text-sm">Payroll Approvals</span>
                                </Link>
                            </Menu.Item>)}

                        {Helpers.authorizeMe(authorizations[2], 'view', 11) &&
                        (
                            <Menu.Item key="payroll/payroll-reports">
                                <Link to="/payroll/payroll-reports">

                                    <span className="pl-7 text-gray-200 text-sm">Payroll Reports</span>
                                </Link>
                            </Menu.Item>)}
                        {Helpers.authorizeMe(authorizations[2], 'view', 11) &&
                        (
                            <Menu.Item key="payroll/global-reports">
                                <Link to="/payroll/global-reports">

                                    <span className="pl-7 text-gray-200 text-sm">Global Reports</span>
                                </Link>
                            </Menu.Item>)}

                    </SubMenu>
                    <SubMenu key="customers"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaUsers/>
                                 </div>
                                 <div className="px-8 -my-3">Customers</div>
                             </div>}>


                        <Menu.Item key="customers/customer-center">
                            <Link to="/customers/customer-center">

                                <span className="pl-7 text-gray-200 text-sm">Customer Center</span>
                            </Link>
                        </Menu.Item>


                    </SubMenu>
                    <SubMenu key="vendors"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaUsers/>
                                 </div>
                                 <div className="px-8 -my-3">Vendors</div>
                             </div>}>


                        <Menu.Item key="employee/vendor-center">
                            <Link to="/employee/vendor-center">

                                <span className="pl-7 text-gray-200 text-sm">Vendor Center</span>
                            </Link>
                        </Menu.Item>


                    </SubMenu>
                    <SubMenu key="industry"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaUsers/>
                                 </div>
                                 <div className="px-8 -my-3">Industries</div>
                             </div>}>


                        <Menu.Item key="industry/industry-center">
                            <Link to="/industry/industry-center">

                                <span className="pl-7 text-gray-200 text-sm">Industry Center</span>
                            </Link>
                        </Menu.Item>

                    </SubMenu>


                    {Helpers.authorizeMe(authorizations[2], 'view', 14) && (
                        <SubMenu key="settings"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaCog/>
                                     </div>
                                     <div className="px-8 -my-3">Settings</div>
                                 </div>}>
                            {Helpers.authorizeMe(authorizations[2], 'view', 14) &&

                            (
                                <Menu.Item key="settings/system-settings">
                                    <Link to="/settings/system-settings">

                                        <span className="pl-7 text-gray-200 text-sm">System Settings</span>
                                    </Link>
                                </Menu.Item>)}

                        </SubMenu>)}

                    <SubMenu key="reports"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">
                                     <FaBars/>
                                 </div>
                                 <div className="px-8 -my-3">Reports</div>
                             </div>}>
                        {Helpers.authorizeMe(authorizations[2], 'view', 11) &&
                        (
                            <Menu.Item key="reports/payroll-reports">
                                <Link to="/reports/payroll-reports">

                                    <span className="pl-7 text-gray-200 text-sm">Payroll Reports</span>
                                </Link>
                            </Menu.Item>)}

                    </SubMenu>

                </MenuItemGroup>

            </Menu>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

