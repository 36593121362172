import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">

        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>


            <Route path={`${match.url}company/start-setup`} component={asyncComponent(() => import('./CompanySetup'))}/>
            <Route path={`${match.url}company/companies`} component={asyncComponent(() => import('./Company'))}/>
            <Route path={`${match.url}company/manage-company/:slug`}
                   component={asyncComponent(() => import('./ManageCompany'))}/>
            <Route path={`${match.url}company/account-chart/:slug`}
                   component={asyncComponent(() => import('./AccountChart'))}/>
            <Route path={`${match.url}company/payroll-items/:slug`}
                   component={asyncComponent(() => import('./PayrollItems'))}/>
            <Route path={`${match.url}company/users/:slug`}
                   component={asyncComponent(() => import('./Users'))}/>
            <Route path={`${match.url}company/employees/:slug`}
                   component={asyncComponent(() => import('./Employees'))}/>


            <Route path={`${match.url}company/accounts`} component={asyncComponent(() => import('./Accounts'))}/>
            <Route path={`${match.url}company/account-summaries`}
                   component={asyncComponent(() => import('./AccountSummary'))}/>
            <Route path={`${match.url}company/items`} component={asyncComponent(() => import('./Items'))}/>

            <Route path={`${match.url}payroll/payroll-approvals`}
                   component={asyncComponent(() => import('./PayrollApprovals'))}/>

            <Route path={`${match.url}payroll/payroll-center`}
                   component={asyncComponent(() => import('./CompanyPayroll'))}/>
            <Route path={`${match.url}payroll/payroll-reports`}
                   component={asyncComponent(() => import('./CompanyPayrollReports'))}/>

            <Route path={`${match.url}payroll/global-reports`}
                   component={asyncComponent(() => import('./CompanyGlobalReports'))}/>


            <Route path={`${match.url}industry/industry-center`}
                   component={asyncComponent(() => import('./Industry'))}/>
            <Route path={`${match.url}settings/system-settings`}
                   component={asyncComponent(() => import('./SystemSettings'))}/>
            <Route path={`${match.url}reports/payroll-reports`}
                   component={asyncComponent(() => import('./PayrollReports'))}/>


        </Switch>
    </div>
);

export default App;
